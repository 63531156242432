.Applications {
  .table {
    tbody {
      cursor: pointer;
    }
    tr.active {
      color: white;
      background-color: rgba(0,0,0,0.8);
    }
  }
}
