.Details {
  .table span {
    display: block;
    font-size: 0.8em;
    font-weight: bold;
  }
}

.Checklist {
  margin-top: 1em;
}
