.Notifications {
  .list-group-item:nth-child(odd) {
    background-color: #f5f5f5;
  }

  .Tabbar {
    margin-bottom: 1em;
  }

  .title {
    font-weight: bold;
    margin-bottom: 0.4em;
  }

  .time {
    font-size: 0.8em;
  }

  .Notification {
    .info {
      display: flex;
    }

    .actions {
      margin-left: auto;
    }
  }
}
