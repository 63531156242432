@keyframes spin{
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.App {
  -webkit-font-smoothing:  antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.signaturepad {
  .actions {
    .btn {
      display: inline-block;
      margin-right: 0.5em;
    }
  }
}

.list-group-item.disabled {
  color: #d9d9d9;
}

.bottom-bar {
  background-color: #efefef;
  padding: 5px;
  text-align: right;
}

h1 {
  margin-bottom: 0.3em;
  font-weight: 900;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position:relative;
  display:inline-block; /*required*/

  @media (min-width:1025px) {
    font-size: 5em;
  }
}
h1:after{
  content: '.';
}
h1, .btn-submit {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMDU4QUZGOyIgLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0eWxlPSJzdG9wLWNvbG9yOiNFNjAwRTY7IiAvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IGZpbGw9InVybCgjZ3JhZGllbnQpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPjwvc3ZnPg==);
  background-image: -webkit-gradient(linear, left top, 45deg, color-stop(0%, #058AFF), color-stop(100%, #E600E6));
  background-image: -moz-linear-gradient(45deg,#058AFF 0%,#E600E6 100%);
  background-image: -webkit-linear-gradient(45deg,#058AFF 0%,#E600E6 100%);
  background-image: -o-linear-gradient(45deg,#058AFF 0%,#E600E6 100%);
  background-image: -ms-linear-gradient(45deg,#058AFF 0%,#E600E6 100%);
  background-image: linear-gradient(45deg,#058AFF 0%,#E600E6 100%);
}
.navbar {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxsaW5lYXJHcmFkaWVudCBpZD0iZ3JhZGllbnQiIHgxPSIwJSIgeTE9IjAlIiB4Mj0iMCUiIHkyPSIxMDAlIj48c3RvcCBvZmZzZXQ9IjAlIiBzdHlsZT0ic3RvcC1jb2xvcjojMDA0QzhFOyIgLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0eWxlPSJzdG9wLWNvbG9yOiM5MDFCOTA7IiAvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IGZpbGw9InVybCgjZ3JhZGllbnQpIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiAvPjwvc3ZnPg==);
  background-image: -webkit-gradient(linear, left top, 135deg, color-stop(0%, #004C8E), color-stop(100%, #901B90));
  background-image: -moz-linear-gradient(135deg,#004C8E 0%,#901B90 100%);
  background-image: -webkit-linear-gradient(135deg,#004C8E 0%,#901B90 100%);
  background-image: -o-linear-gradient(135deg,#004C8E 0%,#901B90 100%);
  background-image: -ms-linear-gradient(135deg,#004C8E 0%,#901B90 100%);
  background-image: linear-gradient(135deg,#004C8E 0%,#901B90 100%);
}
label {
  padding: 0.2rem;
}

.btn-submit {
  border: 0;
}
