.Quiz {
  .is-invalid {
    label {
      color: #dc3545;
    }
  }

  .wrong-answer {
    font-weight: bold;
    color: #dc3545;
  }

  .questions {
    p {
      margin-top: 1em;
      margin-bottom: 0;
    }

    .RadioGroup span {
      margin-right: 1em;
    }
  }

  .picture-questions {
    display: flex;
    align-items: center;

    .RadioGroup > span {
      display: block;
      label {
        margin-bottom: 0;
      }
    }
  }
}
