.Application {
  form {
    .row {
      margin-bottom: 1rem;
    }

    .is-invalid {
      label {
        color: #dc3545;
      }
    }
  }

  .CheckboxGroup, .RadioGroup {
    > div, > span {
      margin-right: 1rem;
      display: inline-block;
    }
  }

  .SingleDatePicker {
    display: inherit;
  }

  .DateInput_input {
    font-size: inherit;
    padding: 7px 11px 6px;
    border-bottom: none;
    line-height: inherit;
  }

  .qstn {
    margin-top: 1rem;
    margin-bottom: 0;
    font-weight: bold;
  }

  .disclaimer {
    padding: 10px;
    background-color: #efefef;
    margin: 10px auto;
    border-radius: 3px;
  }
}
